import { AuthSession, fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

import { logout } from '../auth/authDuck';
import { setLoading } from './loading/loadingDuck';

import { BASE_URL } from '../../constants';
import { store } from '../store';

const ongoingRequests: Map<string, boolean> = new Map();
/**
 * Interceptor function for handling Axios requests and responses.
 *
 * @return {AxiosInstance} The configured Axios instance with interceptors.
 */
const Interceptor = (): AxiosInstance => {
    const { dispatch } = store;
    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `${store.getState().auth.accessToken}`,
        },
    });

    const MAX_RETRIES = 2;
    const RETRY_DELAY = 500;

    axiosRetry(axiosInstance, {
        retries: MAX_RETRIES,
        retryDelay: () => {
            return RETRY_DELAY;
        },
        retryCondition: (err) => {
            //only retry requests that failed with 401 status (unauthorized)
            return err.response?.status === 401;
        },
        onRetry: (retryCount) => {
            if (retryCount < MAX_RETRIES) {
                dispatch(setLoading(true));
            }
        },
    });

    axiosInstance.interceptors.request.use((config: any) => {
        /**
         *
         * `config.params.count` is required for e.Dentify module (vehicle tags)
         * to be able to fire 2 requests: one for GET count and one for GET all
         */
        const requestKey = `${config.method}:${config.url}:${config.params?.count}`;
        /*Under the hood fetchAuthSession() gets the AuthSession object
        If refresh token expired we receive undefined for tokens, logout the user.*/
        return new Promise((resolve, reject) => {
            fetchAuthSession()
                .then(({ tokens }: AuthSession) => {
                    if (tokens) {
                        config.headers.Authorization = tokens.accessToken.toString();
                        // only when we are not in dev mode
                        if (
                            process.env.NODE_ENV !== 'development' &&
                            ongoingRequests.get(requestKey)
                        ) {
                            // Cancel the new request if the same request is in progress
                            reject(config);
                            return;
                        }

                        // Store the request key
                        ongoingRequests.set(requestKey, true);
                        resolve(config);
                    } else if (process.env.JEST_WORKER_ID === undefined) {
                        // means that the refresh token expired
                        dispatch(logout());
                        return;
                    }
                    config.requestKey = requestKey;
                    resolve(config);
                })
                .catch((error) => {
                    // No logged-in user: don't set auth header
                    ongoingRequests.delete(requestKey);
                    resolve(config);
                });
        });
    });

    axiosInstance.interceptors.response.use(
        (response) => {
            const requestKey = (response.config as any).requestKey;
            ongoingRequests.delete(requestKey);
            return response;
        },
        async (error) => {
            const originalConfig = error.config;
            const requestKey = (error.config as any).requestKey;
            ongoingRequests.delete(requestKey);
            if (error.response) {
                if (error.response.status === 401) {
                    //log the user out if retries failed
                    dispatch(setLoading(false));
                    dispatch(logout());
                    return originalConfig;
                }
            }

            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default Interceptor;
