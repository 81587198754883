import { t } from 'i18next';

// available protocols for devices
export enum ProtocolValues {
    HecOneRestAPI = 'HecOne360API',
    OCPP = 'OCPP1.6',
    ChargeCloud = 'chargecloud',
    HecConnect = 'HecConnect',
    OCPI = 'OCPI',
}

// yes/no selection values(e.g. touch display for devices)
export const YES_NO_ITEMS = [
    { name: t('general.labels.yes'), value: 'YES' },
    { name: t('general.labels.no'), value: 'NO' },
];

export const LOG_LEVELS = ['warning', 'info', 'error'];

export const LOG_LEVELS_FILTERS = LOG_LEVELS.map((level) => {
    return { field: 'level', operator: 'eq', value: level };
});

export const MESSAGE_TYPE = [
    'Heartbeat',
    'MeterValues',
    'Installation',
    'RemoteStart',
    'StartTransaction',
    'StatusNotification',
    'Disconnect',
    'Authorize',
    'Abort',
    'Timeout',
];
