import { EDIT_FIELD } from '../../../../constants';

export enum VehicleTagScheme {
    Retail = 'Retail',
    FleetPlus = 'Fleet+',
    ISO = 'ISO',
    ISO_Tab = 'ISO_Tab',
}

export interface VehicleTag {
    id?: string;
    tag_id: string;
    creation_date: string | Date;
    scheme: VehicleTagScheme;
    plate_number: string;
    vehicle_owner: string;
}

export type VehicleTagsRequestBody = {
    vehicle_tags: VehicleTag[];
};

export interface VehicleTagItem extends Omit<VehicleTag, 'mandator_id'> {
    [EDIT_FIELD]?: boolean;
}

export interface VehicleTagsValues {
    vtags: VehicleTagItem[];
    toDelete: VehicleTagItem[];
}
