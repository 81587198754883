import { ComponentType, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    GridCellProps,
    GridDataStateChangeEvent,
    GridDetailRowProps,
    GridItemChangeEvent,
} from '@progress/kendo-react-grid';
import { Field, FieldArrayRenderProps, FieldProps, FormikProps } from 'formik';

// redux
import { useAppDispatch } from '../../../hooks';
import { getUnitsBySiteId, updateEdentifyUnits } from '../edentifyDuck';
import { getPointsBySiteId } from '../../points/pointsDuck';

// common
import { ActionButtonsCell, GridComponent, KendoNoDataMessage, TextCell } from '../../../common';
import { CustomDataState, IGridColumn } from '../../../common/interfaces';
import { SiteUnit, SiteUnitItemValues } from '../../interfaces';
import { getEdentifySiteUnitsColumns } from '../../gridColumns';
import { exitEdit, handleEditButtonClick, handleItemChange } from '../../../../helpers';
import { EDIT_ACTION_BUTTONS, MAXIMUM_CHARACTERS } from '../../../../constants';

const CustomCellForUnitsAssignments =
    (editSiteUnits: (editItem: SiteUnit) => void, modelActionButtons: Map<string, boolean>) =>
    (props: GridCellProps) => (
        <TextCell
            {...props}
            inputType="text"
            minLimit={2}
            maxLimit={MAXIMUM_CHARACTERS}
            component={
                <ActionButtonsCell
                    {...props}
                    path=""
                    index="point_id"
                    component="services.manageEdentify.units"
                    buttonsVisibility={modelActionButtons}
                    editGridRow={editSiteUnits}
                />
            }
        />
    );

export interface SitePointUnitsProps {
    detailRowProps: GridDetailRowProps;
    formikProps: FormikProps<SiteUnitItemValues>;
    arrayHelpers: FieldArrayRenderProps;
}

export const SitePointUnitsEditor = memo(
    ({ detailRowProps, formikProps, arrayHelpers }: SitePointUnitsProps) => {
        const { values } = formikProps;
        const { dataItem } = detailRowProps;
        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        const [siteUnitsServerDataState, setSiteUnitsServerDataState] = useState<CustomDataState>(
            {}
        );
        const [_siteUnitsColumns, setSiteUnitsColumns] = useState<IGridColumn[]>([]);

        const index = useMemo(() => {
            return values.items.findIndex((el) => el.site_id === dataItem.id);
        }, [dataItem.id, values.items]);

        const editSiteUnits = useCallback(
            (editItem: SiteUnit) => {
                const currentSiteUnits = values.items[index].pointUnits;
                if (currentSiteUnits) {
                    const newUnits = handleEditButtonClick(
                        editItem.point_id,
                        currentSiteUnits,
                        'point_id'
                    );

                    arrayHelpers.replace(index, {
                        site_id: dataItem.id,
                        pointUnits: newUnits,
                    });
                }
            },
            [values, arrayHelpers, index, dataItem.id]
        );

        const customColumnForUnitAssignment = new Map<string, ComponentType<GridCellProps>>([
            [
                'unit_tag',
                CustomCellForUnitsAssignments(editSiteUnits, new Map(EDIT_ACTION_BUTTONS)),
            ],
        ]);

        const NoSiteUnitsComponent = useMemo(
            () => <KendoNoDataMessage message={t('points.noDataSite')} />,
            [t]
        );

        /**
         *
         * This function grabs the points and the edentify units for the site,
         * pairs them and adds the resulting object to the units table, which is
         * set on the form as pointUnits
         */
        const fetchSiteUnits = useCallback(
            async (site_id: string, data_state: CustomDataState) => {
                const response = await dispatch(getPointsBySiteId({ data_state, site_id }));
                if (getPointsBySiteId.fulfilled.match(response)) {
                    const unitsRes = await dispatch(getUnitsBySiteId({ data_state, site_id }));
                    dispatch(updateEdentifyUnits(unitsRes.payload));
                    const pointsR = response.payload.data;
                    const edentifyUnitsR = unitsRes.payload.data;

                    const unitsMap = new Map<string, string>();
                    const units: SiteUnit[] = [];

                    if (!pointsR.length) {
                        arrayHelpers.replace(index, {
                            site_id: dataItem.id,
                            pointUnits: [],
                        });
                        return;
                    }

                    for (const unit of edentifyUnitsR) {
                        unitsMap.set(unit.point_id, unit.tag);
                    }
                    for (const point of pointsR) {
                        units.push({
                            name: point.name,
                            point_id: point.id,
                            unit_tag: unitsMap.get(point.id) ?? '',
                        });
                    }

                    arrayHelpers.replace(index, {
                        site_id: dataItem.id,
                        pointUnits: units,
                    });
                }
            },
            [arrayHelpers, dispatch, dataItem.id, index]
        );

        useEffect(() => {
            if (values.items[index].pointUnits === undefined) {
                fetchSiteUnits(dataItem.id, siteUnitsServerDataState);
            }
        }, []);

        const onSiteUnitsDataStateChange = (event: GridDataStateChangeEvent) => {
            const sortState = { sort: event.dataState.sort };
            setSiteUnitsServerDataState(sortState);
            fetchSiteUnits(dataItem.id, sortState);
        };

        const handleGridSiteUnitsChange = (event: GridItemChangeEvent) => {
            const currentUnits = values.items[index].pointUnits;
            if (currentUnits) {
                localStorage.setItem('addOrEdit', 'true');
                const newUnits = handleItemChange(event, currentUnits, 'point_id');

                formikProps.values.items[index] = {
                    site_id: dataItem.id,
                    pointUnits: newUnits,
                };
            }
        };

        const updateRowData = () => {
            const siteUnitsData = values.items[index].pointUnits;
            if (siteUnitsData) {
                const newUnits = exitEdit(siteUnitsData);

                arrayHelpers.replace(index, {
                    site_id: dataItem.id,
                    pointUnits: newUnits,
                });
            }
        };

        return (
            <Field name={`items.${index}.pointUnits`}>
                {({ field }: FieldProps) => {
                    return (
                        <GridComponent
                            result={field.value || []}
                            columns={getEdentifySiteUnitsColumns(customColumnForUnitAssignment)}
                            initialColumns={getEdentifySiteUnitsColumns()}
                            setColumns={setSiteUnitsColumns}
                            serverDataState={siteUnitsServerDataState}
                            onServerDataStateChange={onSiteUnitsDataStateChange}
                            noDataMessage={NoSiteUnitsComponent}
                            handleItemChange={handleGridSiteUnitsChange}
                            updateRowData={updateRowData}
                            isPageable={false}
                            className="site-units-edit-grid"
                            enterCellEdit={editSiteUnits}
                        />
                    );
                }}
            </Field>
        );
    }
);
