import { ComponentType } from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';

import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../../constants';
import { IGridColumn } from '../../../common/interfaces';

export const getEdentifyUnitsColumns = (): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'sites.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};

export const getEdentifySiteUnitsColumns = (
    customCells?: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'edentify.pointName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'unit_tag',
            title: 'edentify.units.singular',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('unit_tag'),
            sortable: false,
        },
    ];
};
