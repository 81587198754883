import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import * as Yup from 'yup';

//redux
import { useAppSelector, useAppDispatch, useRights } from '../../../hooks';
import { getSites } from '../../sites/siteDuck';
import { updateEdentifyUnitsRequest } from '../edentifyDuck';

// common components, interfaces, constants and helpers
import { SitePointUnitsEditor } from './SiteUnitsEdit';
import { SitePointUnitsViewer } from './SiteUnitsView';
import {
    ComponentHeader,
    FormComponentFooter,
    GridComponent,
    KendoNoDataMessage,
} from '../../../common';
import { CustomDataState, IGridColumn } from '../../../common/interfaces';
import { EdentifyUnit, ExtendedGetReqProps, SiteUnitItemValues } from '../../interfaces';
import { getEdentifyUnitsColumns } from '../../gridColumns';
import {
    getInitialGridState,
    preventSubmit,
    sanitizeFields,
    showToaster,
    getStringValidations,
    openLeavePageModal,
    navigateToPath,
} from '../../../../helpers';
import { Operations, SUCCESSFUL_ACTION, ToasterType } from '../../../../constants';

const rightsMap = new Map([['subComponent', 'services.manageEdentify.units']]);

const EdentifyUnits = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { sites } = useAppSelector((state) => state.sites);
    const rights = useRights(rightsMap);

    const [siteUnitsServerDataState, setSiteUnitsServerDataState] = useState<CustomDataState>(
        getInitialGridState('name')
    );
    const [columns, setColumns] = useState<IGridColumn[]>(getEdentifyUnitsColumns());

    const formRef = useRef<FormikProps<SiteUnitItemValues>>(null);

    const getSitesData = useCallback(
        (mandatorId: string, serverDataState: CustomDataState) => {
            const requestObj: ExtendedGetReqProps = {
                data_state: serverDataState,
                mandator_id: mandatorId,
            };

            dispatch(getSites(requestObj));
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedMandator?.id) {
            getSitesData(selectedMandator?.id, {});
        }
    }, [getSitesData, selectedMandator?.id]);

    const NoSitesComponent = useMemo(() => <KendoNoDataMessage message={t('sites.noData')} />, [t]);

    function getInitialValues() {
        const initialValues: SiteUnitItemValues = { items: [] };
        for (const site of sites.data) {
            initialValues.items.push({ site_id: site.id, pointUnits: undefined });
        }

        return initialValues;
    }

    async function handleSubmit(fields: SiteUnitItemValues) {
        fields = sanitizeFields(fields);

        const unitsToUpdate: EdentifyUnit[] = [];
        for (const field of fields.items) {
            const site_id = field.site_id;

            if (field.pointUnits?.length) {
                for (const unit of field.pointUnits) {
                    unitsToUpdate.push({
                        site_id,
                        tag: unit.unit_tag,
                        point_id: unit.point_id,
                    });
                }
            }
        }

        const response = await dispatch(updateEdentifyUnitsRequest({ units: unitsToUpdate }));
        if (updateEdentifyUnitsRequest.fulfilled.match(response)) {
            showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Updated);

            localStorage.removeItem('addOrEdit');
        }
    }

    function onEdentifyUnitsDataStateChange(event: GridDataStateChangeEvent) {
        if (selectedMandator?.id) {
            const { filter, group, ...state } = event.dataState;
            setSiteUnitsServerDataState({ ...siteUnitsServerDataState, ...state });
            getSitesData(selectedMandator.id, state);
        }
    }

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                items: Yup.array().of(
                    Yup.object().shape({
                        pointUnits: Yup.array().of(
                            Yup.object().shape({
                                unit_tag: getStringValidations(
                                    0,
                                    100,
                                    t('edentify.units.singular'),
                                    false
                                ),
                            })
                        ),
                    })
                ),
            }),
        [t]
    );

    async function validateSiteUnitsForm() {
        const res = await formRef.current?.validateForm();
        if (res?.items) {
            showToaster(ToasterType.Error, t('general.validations.invalidForm'));
        }
    }

    function returnToEdentifyOverview() {
        if (localStorage.addOrEdit === 'true') {
            openLeavePageModal(() => navigateToPath(navigate, '/edentify'));
        }
    }

    return (
        <>
            <ComponentHeader title={t('services.manageEdentify.units')} />
            {rights?.subComponent?.edit ? (
                <Formik
                    innerRef={formRef}
                    initialValues={getInitialValues()}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}>
                    {(formikProps) => (
                        <Form noValidate onKeyDown={preventSubmit}>
                            <FieldArray name="items">
                                {(arrayHelpers) => (
                                    <Box>
                                        <GridComponent
                                            result={sites}
                                            columns={columns}
                                            initialColumns={getEdentifyUnitsColumns()}
                                            setColumns={setColumns}
                                            serverDataState={siteUnitsServerDataState}
                                            detailComponent={(props) => (
                                                <SitePointUnitsEditor
                                                    detailRowProps={props}
                                                    formikProps={formikProps}
                                                    arrayHelpers={arrayHelpers}
                                                />
                                            )}
                                            noDataMessage={NoSitesComponent}
                                            isPageable={false}
                                            defaultMaxHeight={'600px'}
                                            className="edentify-units-grid"
                                        />
                                    </Box>
                                )}
                            </FieldArray>

                            <FormComponentFooter
                                handleFormValidation={validateSiteUnitsForm}
                                cancelAction={returnToEdentifyOverview}
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <GridComponent
                    result={sites}
                    columns={columns}
                    initialColumns={getEdentifyUnitsColumns()}
                    setColumns={setColumns}
                    serverDataState={siteUnitsServerDataState}
                    onServerDataStateChange={onEdentifyUnitsDataStateChange}
                    detailComponent={SitePointUnitsViewer}
                    noDataMessage={NoSitesComponent}
                    isPageable={true}
                    className="edentify-units-grid"
                />
            )}
        </>
    );
};

export default EdentifyUnits;
