import { MouseEvent, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../../i18n';
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { Person, ArrowDropDown, ArrowDropUp, Lock, Notifications } from '@mui/icons-material';

// redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { displayModal } from '../../modal/modalDuck';

// common components, interfaces, constants and helpers
import { Logout } from '../../../auth';
import { SelectComponent } from '../../index';
import { ComponentWidthProps } from '../../interfaces';
import { BREAKPOINTS, MODAL_TYPES } from '../../../../constants/general';
import { LANGUAGES } from '../../../../constants/languages';
import { sortByField } from '../../../../helpers';

/**
 * Renders a profile menu component.
 * @param {ComponentWidthProps} props - The props object containing the following properties:
 * @param {number} props.width - The width of the component.
 * @param {number} props.maxWidth - The maximum width of the component.
 * @return {JSX.Element} The rendered profile menu component.
 */
const ProfileMenu = ({ width, maxWidth }: ComponentWidthProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { loggedUser } = useAppSelector((state) => state.auth);
    const { language } = useAppSelector((state) => state.commonData);
    const { openNotificationCount } = useAppSelector((state) => state.notifications);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const countryCode = LANGUAGES.find((lang) => lang.code === language)?.countryCode;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangePassword = () => {
        dispatch(
            displayModal({
                showModal: true,
                title: t('password.changePassword'),
                type: MODAL_TYPES.ChangeOldPassword,
            })
        );
        handleClose();
    };

    const handleLangChange = (e: SelectChangeEvent<unknown>) => {
        const value = e.target.value as string;
        i18n.changeLanguage(value);
        // close profile popup
        handleClose();
    };

    const releaseNotesredirect = useCallback(async () => {
        navigate('/notification/list');
    }, [navigate]);

    const sortedLanguages = useMemo(() => sortByField(LANGUAGES, 'name'), []);
    sortedLanguages.map((lang) => (lang.name = t(`general.languages.${lang.code}`)));

    return (
        <>
            <IconButton
                sx={{ color: '#fff' }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <Person sx={{ mr: { sm: 1 } }} />

                {width > BREAKPOINTS.md && (
                    <Typography variant="subtitle1" noWrap sx={{ maxWidth: maxWidth }}>
                        {`${loggedUser?.first_name} ${loggedUser?.last_name}`}
                    </Typography>
                )}
                {open ? <ArrowDropUp /> : <ArrowDropDown />}
                {openNotificationCount > 0 ? <div className="notification-dot"></div> : <div></div>}
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={onChangePassword}>
                    <ListItemIcon>
                        <Lock fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="h5">{t('password.changePassword')}</Typography>
                </MenuItem>

                <MenuItem sx={{ color: '#343A40' }}>
                    <SelectComponent
                        data={sortedLanguages}
                        onChange={handleLangChange}
                        valueEntry={'code'}
                        titleEntry={'name'}
                        defaultValue={language}
                        countryValue={countryCode}
                        className="header-profile-selection"
                    />
                </MenuItem>
                <MenuItem onClick={releaseNotesredirect}>
                    <ListItemIcon>
                        <Notifications fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="h5">{t('notifications.notifications')}</Typography>
                </MenuItem>

                <Logout />
            </Menu>
        </>
    );
};
export default ProfileMenu;
