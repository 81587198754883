import { ComponentType } from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';

import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../../constants';
import { IGridColumn } from '../../../common/interfaces';

export const getVehicleTagsColumns = (
    customCells?: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'tag_id',
            title: 'edentify.vehicleTags.singular',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            cell: customCells?.get('tag_id'),
            show: COLUMN_VISIBLE,
            sortable: true,
        },
        {
            field: 'scheme',
            title: 'edentify.vehicleTags.scheme',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            cell: customCells?.get('scheme'),
            show: COLUMN_VISIBLE,
        },
        {
            field: 'creation_date',
            title: 'general.labels.createdAt',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            cell: customCells?.get('creation_date'),
            show: COLUMN_VISIBLE,
        },
        {
            field: 'plate_number',
            title: 'edentify.vehicleTags.plateNumber',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            cell: customCells?.get('plate_number'),
            show: COLUMN_VISIBLE,
        },
    ];
};
