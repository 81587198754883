import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RemoteActionStates, URLS } from '../../../../constants';
import { Interceptor } from '../../../common';
import {
    PatchTransactionProps,
    PostUploadedFileProps,
    SoftwareUpdateProps,
    PostActionProps,
    PostTransaction,
    PutActionProps,
    RemoteControlTypes,
} from '../../interfaces';

const initialState: RemoteControlTypes = {
    loadingSpinners: [],
    remoteActions: null,
};
interface LogErrorInterface {
    log: [
        {
            description: string;
            level: string;
            timestamp: string;
        },
    ];
}
interface GeneralErrorInterface {
    code: string;
    internal_message: string;
    status: string;
    user_message: string;
}
interface ErrorDataType {
    data?: { articles: LogErrorInterface[]; payments: LogErrorInterface[] };
    errors?: GeneralErrorInterface[];
}

const generateErrorObject = (error: AxiosError<ErrorDataType>) => {
    let errorObject = {};
    if (error.response?.data.errors) {
        errorObject = error.response;
    } else if (
        error.response?.data.data?.payments[0].log &&
        error.response?.data.data.payments[0].log[0].level === RemoteActionStates.Error
    ) {
        errorObject = { message: error.response.data.data.payments[0].log[0].description };
    } else {
        errorObject =
            error.response?.data.data?.articles[0]?.log[0].level === RemoteActionStates.Error
                ? {
                      message: error.response.data.data.articles[0].log[0].description,
                  }
                : {};
    }
    return errorObject;
};
// create a transaction with articles and payments
export const createTransaction = createAsyncThunk(
    'remoteControl/transaction/create',
    async (transaction: PostTransaction, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(`${URLS.Transactions}`, transaction);
            return response.data;
        } catch (err) {
            const error = err as AxiosError<ErrorDataType>;
            return rejectWithValue(generateErrorObject(error));
        }
    }
);

// upload software version file to S3 bucket
export const postUploadedFile = createAsyncThunk(
    'remoteControl/softwareUpdate/postFile',
    async ({ mandator_id, site_id, device_id }: PostUploadedFileProps, { rejectWithValue }) => {
        try {
            const url = `${URLS.SoftwareUpdateFiles}?mandator_id=${mandator_id}&site_id=${site_id}&device_id=${device_id}`;
            const response = await Interceptor().post(url);
            return response.data;
        } catch (err) {
            const error = err as AxiosError<ErrorDataType>;
            return rejectWithValue(generateErrorObject(error));
        }
    }
);

// update device software version
export const updateSoftwareVersion = createAsyncThunk(
    'remoteControl/softwareUpdate/update',
    async ({ device_id, update_software_data }: SoftwareUpdateProps, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Devices}/${device_id}/software_version`, {
                ...update_software_data,
            });
        } catch (err) {
            const error = err as AxiosError<ErrorDataType>;
            return rejectWithValue(generateErrorObject(error));
        }
    }
);

// patch transaction
export const patchTransaction = createAsyncThunk(
    'remoteControl/transaction/patch',
    async ({ transaction_id, requestObject }: PatchTransactionProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(`${URLS.Transactions}/${transaction_id}`, {
                ...requestObject,
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError<ErrorDataType>;
            return rejectWithValue(generateErrorObject(error));
        }
    }
);

// remote control actions
// create a thunk for get user remote actions
export const getOngoingActions = createAsyncThunk(
    'remoteControl/userActions/list',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.RemoteControlActions}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// add user action in history
export const addUserAction = createAsyncThunk(
    'remoteControl/userActions/add',
    async (action: PostActionProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(`${URLS.RemoteControlActions}`, {
                ...action,
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const updateUserAction = createAsyncThunk(
    'remoteControl/userActions/update',
    async (action: PutActionProps, { rejectWithValue }) => {
        try {
            const { id, ...updatedUserAction } = action;
            return await Interceptor().put(`${URLS.RemoteControlActions}/${id}`, {
                ...updatedUserAction,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const remoteControlSlice = createSlice({
    name: 'remoteControl',
    initialState,
    reducers: {
        addSpinner: (state, { payload }) => {
            if (!state.loadingSpinners.includes(payload)) {
                state.loadingSpinners.push(payload);
            }
        },
        clearSpinner: (state, { payload }) => {
            const index = state.loadingSpinners.indexOf(payload);
            if (index > -1) {
                state.loadingSpinners.splice(index, 1);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOngoingActions.fulfilled, (state, { payload }) => {
            state.remoteActions = payload.data;
        });
    },
});

export const { addSpinner, clearSpinner } = remoteControlSlice.actions;
export default remoteControlSlice.reducer;
