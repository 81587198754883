import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { MIN_COLUMN_WIDTH, COLUMN_VISIBLE } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

/**
 * Returns an array of column definitions for the Transactions grid.
 *
 * @param {{ CustomCell?: ComponentType<GridCellProps>, transactionsColumns?: boolean }} props
 * @property {ComponentType<GridCellProps>} [CustomCell] - A custom cell component.
 * @property {boolean} [transactionsColumns] - If true, the grid will contain additional columns.
 *
 * @returns {IGridColumn[]} An array of column definitions.
 */
export const getTransactionsColumns = (
    CustomCell?: ComponentType<GridCellProps>,
    transactionsColumns?: boolean
): IGridColumn[] => {
    return [
        {
            field: 'invoice.created_at',
            title: 'transactions.dateAndTime',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'site.name',
            title: 'sites.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        transactionsColumns
            ? {
                  field: 'device.name',
                  title: 'devices.entityName',
                  filter: 'text',
                  minWidth: MIN_COLUMN_WIDTH,
                  show: COLUMN_VISIBLE,
              }
            : {},
        {
            field: 'device.code',
            title: 'devices.code',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: !COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'point.name',
            title: 'points.entityName',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: transactionsColumns ? 'invoice.paid_amount' : 'invoice.amount',
            title: 'transactions.amount',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'invoice.currency',
            title: 'sites.currency',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        transactionsColumns
            ? {
                  field: 'payments.medium',
                  title: 'transactions.paymentMethod',
                  filter: 'text',
                  minWidth: MIN_COLUMN_WIDTH,
                  show: COLUMN_VISIBLE,
                  sortable: false,
              }
            : {},
        transactionsColumns
            ? {
                  field: 'card_issuer.driver_name',
                  title: 'cards.cardOwner',
                  filter: 'text',
                  minWidth: MIN_COLUMN_WIDTH,
                  show: !COLUMN_VISIBLE,
                  sortable: false,
              }
            : {},
        transactionsColumns
            ? {
                  field: 'card_issuer.vehicle_number',
                  title: 'cards.vehicle',
                  filter: 'text',
                  minWidth: MIN_COLUMN_WIDTH,
                  show: !COLUMN_VISIBLE,
                  sortable: false,
              }
            : {},
        {
            field: transactionsColumns ? 'payments.card_id' : 'state',
            title: transactionsColumns ? 'transactions.cardId' : 'general.labels.status',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: CustomCell,
        },
    ];
};

export const getTransactionInfoColumns = (): IGridColumn[] => {
    return [
        {
            field: 'productName',
            title: 'products.product',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'quantity',
            title: 'transactions.quantity',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'unitMeasure',
            title: 'products.unitMeasure',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'unitPrice',
            title: 'general.labels.unitPrice',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'amount',
            title: 'transactions.amount',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
    ];
};
