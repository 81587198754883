import { useCallback } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationMessageModalProps } from '../../pages/interfaces';
import { useAppDispatch } from '../../hooks';
import { markviewedNotification } from '../../pages/payment/notifications/notificationDuck';
import { staticColors } from '../../../themes/themes';

const NotificationMessageModal = ({
    onClose,
    notificationMessage,
}: NotificationMessageModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const notificationId = notificationMessage?.id;
    const htmlBody = notificationMessage?.body;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlBody as string;

    // Extract the text content
    const textContent = tempDiv.textContent || tempDiv.innerText || '';

    // Extract image URLs
    const imgTags = tempDiv.querySelectorAll('img');
    const imgSrcs = Array.from(imgTags).map((img) => img.src);
    const dateStr = notificationMessage
        ? notificationMessage?.activation_date
        : '2024-09-01T00:00:00Z';
    const date = new Date(dateStr ?? '');
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).format(date);
    const tagsNames = notificationMessage?.tags.map((item) => item.name);

    const tagStyles = [
        { backgroundColor: staticColors.improvement }, // Improvement
        { backgroundColor: staticColors.green }, // New Feature
        { backgroundColor: staticColors.warningText }, // Bug Fixes
    ];

    const marknotificationRead = () => {
        markNotification(notificationId ?? '');
        onClose();
    };

    const markNotification = useCallback(
        async (notification_id: string) => {
            dispatch(markviewedNotification(notification_id));
        },
        [dispatch]
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 4,
                borderRadius: '8px',
                height: '532px',
                margin: 'auto',
                width: '876px',
            }}>
            <Typography
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    mb: 2,
                }}>
                <span style={{ color: '#0093d3', fontSize: '20px' }}>{formattedDate}</span>
                <Box>
                    {tagsNames &&
                        tagsNames.map((tag, index) => (
                            <span
                                key={index}
                                style={{
                                    ...tagStyles[index],
                                    padding: '4px 8px',
                                    borderRadius: '12px',
                                    marginRight: '4px',
                                    color: '#fff',
                                    fontSize: '16px',
                                }}>
                                {tag}
                            </span>
                        ))}
                </Box>
            </Typography>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    mb: 2,
                }}
                color="secondary">
                {notificationMessage ? notificationMessage.subject : null}
            </Typography>
            <Typography
                sx={{
                    fontSize: '16px',
                    mb: 4,
                }}
                color="secondary">
                {textContent}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}>
                    {imgSrcs.map((url, index) => (
                        <Box
                            key={index}
                            component="img"
                            src={url}
                            sx={{
                                height: '100px',
                                width: '100px',
                            }}
                        />
                    ))}
                </Box>
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    mt: 'auto',
                }}>
                <Button
                    variant="contained"
                    onClick={marknotificationRead}
                    sx={{
                        backgroundColor: '#d32f2f',
                        color: '#fff',
                        width: '100px',
                        height: '40px',
                        borderRadius: '8px',
                    }}>
                    {t('general.labels.ok')}
                </Button>
            </Box>
        </Box>
    );
};

export default NotificationMessageModal;
