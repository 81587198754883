import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ChartTypes, INITIAL_DATA_STATE, URLS } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
import {
    EdentifyInitialStateProps,
    EdentifyUnitsRequestBody,
    ExtendedGetReqProps,
    VehicleTagsRequestBody,
} from '../interfaces';

const initialState: EdentifyInitialStateProps = {
    graphs: {
        [ChartTypes.EdentifyUnitsCount]: {},
        [ChartTypes.VehicleTagsCount]: {},
        [ChartTypes.EdentifyUnitsGrid]: {},
        [ChartTypes.VehicleTagsGrid]: {},
    },
    breakpoint: 'lg',
    vehicleTags: INITIAL_DATA_STATE,
    edentifyUnits: INITIAL_DATA_STATE,
    sites: INITIAL_DATA_STATE,
};

interface EdentifyUnitsDataReqProps extends ExtendedGetReqProps {
    site_id: string;
}

export const updateEdentifyUnitsRequest = createAsyncThunk(
    'edentify/edentifyUnits/update',
    async ({ units }: EdentifyUnitsRequestBody, { rejectWithValue }) => {
        try {
            if (units.length <= 100) {
                const response = await Interceptor().put(`${URLS.EdentifyUnits}`, {
                    units,
                });
                return response.data;
            }
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const createVehicleTagsRequest = createAsyncThunk(
    'edentify/vehicleTags/add',
    async ({ vehicle_tags }: VehicleTagsRequestBody, { rejectWithValue }) => {
        try {
            if (vehicle_tags.length <= 100) {
                const response = await Interceptor().post(`${URLS.VehicleTags}`, {
                    vehicle_tags,
                });
                return response.data;
            }
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
export const updateVehicleTagsRequest = createAsyncThunk(
    'edentify/vehicleTags/update',
    async ({ vehicle_tags }: VehicleTagsRequestBody, { rejectWithValue }) => {
        try {
            if (vehicle_tags.length <= 100) {
                const response = await Interceptor().put(`${URLS.VehicleTags}`, {
                    vehicle_tags,
                });
                return response.data;
            }
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
export const deleteVehicleTagsRequest = createAsyncThunk(
    'edentify/vehicleTags/delete',
    async ({ vehicle_tags }: VehicleTagsRequestBody, { rejectWithValue }) => {
        try {
            if (vehicle_tags.length <= 100) {
                let ok = true;
                for (const tag of vehicle_tags) {
                    const response = await Interceptor().delete(`${URLS.VehicleTags}/${tag.id}`);

                    if (!response) {
                        ok = false;
                    }
                }
                return ok;
            }
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getVehicleTagsData = createAsyncThunk(
    'edentify/vehicleTags/list',
    async ({ data_state, mandator_id }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.VehicleTags}`, {
                params: {
                    mandator_id,
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getEdentifyUnitsCount = createAsyncThunk(
    'edentify/edentifyUnits/count',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.EdentifyUnits}`, {
                params: {
                    mandator_id,
                    count: true,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getVehicleTagsCount = createAsyncThunk(
    'edentify/vehicleTags/count',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const response = await Interceptor().get(`${URLS.VehicleTags}`, {
                params: {
                    mandator_id,
                    count: true,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getUnitsBySiteId = createAsyncThunk(
    'edentify/siteUnits',
    async (
        { data_state, site_id }: Omit<EdentifyUnitsDataReqProps, 'mandator_id'>,
        { rejectWithValue }
    ) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.EdentifyUnits}`, {
                params: {
                    site_id,
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const edentifySlice = createSlice({
    name: 'edentify',
    initialState,
    reducers: {
        handleBreakPointChange: (state, action) => {
            state.breakpoint = action.payload;
        },
        updateGraphs: (state, action) => {
            if (state.graphs) {
                state.graphs = {
                    ...action.payload,
                    edentifyUnitsGrid: state.graphs.edentifyUnitsGrid,
                    vehicleTagsGrid: state.graphs.vehicleTagsGrid,
                };
            }
        },
        resetGraphs: (state) => {
            state.graphs = initialState.graphs;
        },
        updateEdentifyUnitsGridGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                edentifyUnitsGrid: action.payload,
            };
        },
        updateEdentifyUnits: (state, action) => {
            state.edentifyUnits.data = action.payload;
        },
        updateVehicleTagsGridGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                vehicleTagsGrid: action.payload,
            };
        },
        resetEdentifyUnitsGrid: (state) => {
            state.edentifyUnits = initialState.edentifyUnits;
        },
        resetVehicleTagsGrid: (state) => {
            state.vehicleTags = initialState.vehicleTags;
        },
        updateUnitsCountGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                edentifyUnitsCount: action.payload,
            };
        },
        updateTagsCountGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                vehicleTagsCount: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVehicleTagsData.fulfilled, (state, { payload }) => {
            state.vehicleTags.data = [...payload.data];
            state.vehicleTags.total = payload.meta.total;
        });
        builder.addCase(updateEdentifyUnitsRequest.fulfilled, (state, { payload }) => {
            state.edentifyUnits.data = [...payload.data];
            state.edentifyUnits.total = payload.meta.total;
        });
    },
});

export const {
    updateGraphs,
    updateUnitsCountGraph,
    updateTagsCountGraph,
    resetGraphs,
    handleBreakPointChange,
    updateVehicleTagsGridGraph,
    updateEdentifyUnitsGridGraph,
    resetVehicleTagsGrid,
    resetEdentifyUnitsGrid,
    updateEdentifyUnits,
} = edentifySlice.actions;
export default edentifySlice.reducer;
