import { t } from 'i18next';

import {
    configureStore,
    isRejectedWithValue,
    Middleware,
    isFulfilled,
    combineReducers,
    Action,
} from '@reduxjs/toolkit';

import { Operations, SUCCESSFUL_ACTION, ToasterType } from './../constants';
import { showToaster } from '../helpers';
// CORE
import authReducer from './auth/authDuck';
import cardsReducer from './pages/cards/cardDuck';
import commonDataReducer from './common/commonDataDuck';
import dashboardReducer from './pages/dashboard/dashboardDuck';
import devicesReducer from './pages/devices/deviceDuck';
import formReducer from './common/formControls/formDuck';
import historiesReducer from './pages/history/historyDuck';
import layoutReducer from './common/layout/layoutDuck';
import loadingReducer from './common/loading/loadingDuck';
import mandatorsReducer from './pages/mandators/mandatorDuck';
import manufacturersReducer from './pages/manufacturers/manufacturersDuck';
import modalReducer from './common/modal/modalDuck';
import pointsReducer from './pages/points/pointsDuck';
import pricesReducer from './pages/prices/pricesDuck';
import productsAssignmentReducer from './pages/productsAssignment/productAssignmentDuck';
import productsReducer from './pages/products/productDuck';
import protocolsReducer from './pages/protocols/protocolsDuck';
import providerFieldsReducer from './pages/providerFields/providerFieldsDuck';
import providersReducer from './pages/providers/providerDuck';
import providerTemplatesReducer from './pages/providerTemplates/providerTemplateDuck';
import remoteControlReducer from './pages/devices/remoteControl/remoteControlDuck';
import reportsReducer from './pages/reports/reportsDuck';
import rolesReducer from './pages/userPermissions/rolesDuck';
import sitesReducer from './pages/sites/siteDuck';
import themeDuck from '../themes/themeDuck';
import transactionsChartsReducer from './pages/transactions/transactionChartsDuck';
import transactionsReducer from './pages/transactions/transactionsDuck';
import twintReducer from './pages/twint/twintDuck';
import userRightsReducer from './common/layout/userRightsDuck';
import usersReducer from './pages/users/usersDuck';

// FLEETNET
import cardIssuersReducer from './fleetnet-router/pages/cardIssuer/cardIssuerDuck';
import cardProfilesReducer from './fleetnet-router/pages/CardProfile/cardProfileDuck';
import fleetnetDevicesReducer from './fleetnet-router/pages/FleetnetDevices/fleetnetDeviceDuck';
import fleetnetSitesReducer from './fleetnet-router/pages/FleetnetSites/fleetnetSiteDuck';
import hostProviderReducer from './fleetnet-router/pages/hostProvider/hostProviderDuck';

// PARKING
import freeParkingConfigurationAssignmentsReducer from './pages/parking/freeParking/siteAssignments/configurationAssignmentsDuck';
import freeParkingConfigurationsReducer from './pages/parking/freeParking/configurationDuck';

// CORE - MARKETPLACE
import digitalReceiptsReducer from './pages/digitalReceipts/receiptTemplates/digitalReceiptsDuck';
import emailDeliveryReducer from './pages/digitalReceipts/emailDelivery/emailDeliveryDuck';
import marketplaceReducer from './pages/marketplace/marketplaceDuck';
import receiptSiteAssignmentsReducer from './pages/digitalReceipts/siteAssignments/siteAssignmentsDuck';

// EMOBILITY
import edentifyReducer from './pages/edentify/edentifyDuck';

// PAYMENT
import notificationsReducer from './pages/payment/notifications/notificationDuck';

interface ErrorModel {
    code: number | string;
    internal_message: string;
    more_info: string;
    user_message: string;
}

const excludedActions = [
    'dashboard/layouts/fulfilled',
    'marketplace/module/activation/fulfilled',
    'marketplace/module/status/fulfilled',
    'remoteControl/actions/add/fulfilled',
    'remoteControl/actions/update/fulfilled',
    'remoteControl/createTransaction/fulfilled',
    'test-host-configuration/fulfilled',
];

const excludedRejectedActions = [
    'marketplace/module/fields/rejected',
    'marketplace/module/status/rejected',
];

/**
 * Shows a toast message with the given error or warning message.
 *
 * @param {string} errorMessage - The error message to display.
 * @param {string} [warningMessage] - The optional warning message to display.
 * @return {void} This function does not return anything.
 */
const showMessageToaster = (errorMessage: string, warningMessage?: string): void => {
    if (warningMessage) {
        // in case of 403 status
        showToaster(ToasterType.Warning, warningMessage);
        return;
    }
    showToaster(ToasterType.Error, errorMessage);
};

/**
 * Processes the error code and displays an appropriate toast message.
 *
 * @param {ErrorModel} error - The error object containing the code and user message.
 * @return {void} This function does not return anything.
 */
const processErrorCode = (error: ErrorModel): void => {
    const { code, user_message } = error;

    if (code === 1) {
        showToaster(ToasterType.Error, user_message, '', true);
        return;
    }
    let translatedError = t(`errorCodes.code${code ?? ''}`);
    if (translatedError?.startsWith('errorCodes.')) {
        translatedError = user_message;
    }
    //another translate is needed here to check if error code is 'known'
    //translation from toaster would be called again and wasted without extra parameter
    showToaster(ToasterType.Error, translatedError, '', true);
};

/**
 * Sets the rejected error based on the given action.
 *
 * @param {any} action - The action object.
 * @return {void}
 */
const setRejectedError = (action: any): void => {
    const data = action.payload.data;
    if (data?.message) {
        showToaster(ToasterType.Error, data.message);
        return;
    }
    if (data?.errors && data.errors.length > 0) {
        const pathname = window.location.pathname;
        if (pathname.includes('edit') || pathname.includes('view')) {
            if (action.payload.status === 404) {
                window.history.back();
                return;
            }
        }
        data.errors.forEach((error: ErrorModel) => {
            processErrorCode(error);
        });
        return;
    }
    if (action.type.indexOf('auth') === -1) {
        showMessageToaster(action.payload.message, action.payload.Message);
    }
};

/**
 * Middleware function that intercepts the error messages and the success ones.
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action: any) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (!action.payload) {
        return next(action);
    }

    if (isRejectedWithValue(action) && excludedRejectedActions.indexOf(action.type) === -1) {
        setRejectedError(action);
    } else if (
        isFulfilled(action) &&
        action.payload.status === 201 &&
        excludedActions.indexOf(action.type) === -1
    ) {
        showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Created);
    }

    return next(action);
};

const combinedReducer = combineReducers({
    auth: authReducer,
    cardIssuers: cardIssuersReducer,
    cardProfiles: cardProfilesReducer,
    cards: cardsReducer,
    commonData: commonDataReducer,
    dashboard: dashboardReducer,
    devices: devicesReducer,
    digitalReceipts: digitalReceiptsReducer,
    edentify: edentifyReducer,
    emailDelivery: emailDeliveryReducer,
    fleetnetDevices: fleetnetDevicesReducer,
    fleetnetSites: fleetnetSitesReducer,
    formData: formReducer,
    freeParkingConfigurationAssignments: freeParkingConfigurationAssignmentsReducer,
    freeParkingConfigurations: freeParkingConfigurationsReducer,
    histories: historiesReducer,
    hostProviders: hostProviderReducer,
    layout: layoutReducer,
    loading: loadingReducer,
    mandators: mandatorsReducer,
    manufacturers: manufacturersReducer,
    marketplace: marketplaceReducer,
    modal: modalReducer,
    points: pointsReducer,
    prices: pricesReducer,
    products: productsReducer,
    productsAssignment: productsAssignmentReducer,
    reports: reportsReducer,
    protocols: protocolsReducer,
    providerFields: providerFieldsReducer,
    providers: providersReducer,
    providerTemplates: providerTemplatesReducer,
    receiptSiteAssignments: receiptSiteAssignmentsReducer,
    remoteControl: remoteControlReducer,
    roles: rolesReducer,
    sites: sitesReducer,
    theme: themeDuck,
    transactions: transactionsReducer,
    transactionsCharts: transactionsChartsReducer,
    twintSites: twintReducer,
    userRights: userRightsReducer,
    users: usersReducer,
    notifications: notificationsReducer,
});

/**
 * The root reducer for the Redux store. It handles the logic for updating the state
 * based on the dispatched actions. If the action type contains 'logout/fulfilled',
 * it clears the state. Otherwise, it passes the state and action to the combined
 * reducer for further processing.
 *
 * @param {any} state - The current state of the Redux store.
 * @param {Action} action - The dispatched action.
 * @return {any} The updated state after the reducer logic has been applied.
 */
const rootReducer = (state: any, action: Action) => {
    if (action.type.indexOf('logout/fulfilled') > -1) {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(rtkQueryErrorLogger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
