import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ChartTypes, URLS } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
import { GraphResponse } from '../../common/interfaces';
import { GetTransactionsProps } from '../interfaces';

interface ChartsStateTypes {
    graphs?: GraphResponse | null;
}

// create a thunk for getting charts data
export const getTransactionsStatistics = createAsyncThunk(
    'transactionsCharts/statistics',
    async (
        { data_state, mandator_id, start_date, end_date, fields }: GetTransactionsProps,
        { rejectWithValue }
    ) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.TransactionsStatistics}`, {
                params: {
                    mandator_id,
                    start_date,
                    end_date,
                    fields: fields?.join(),
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const initialState: ChartsStateTypes = {
    graphs: {
        [ChartTypes.TotalAmount]: {},
        [ChartTypes.Transactions]: {},
        [ChartTypes.DailyTransactions]: {},
    },
};
const transactionsChartsSlice = createSlice({
    name: 'transactionsCharts',
    initialState,
    reducers: {
        updateGraphs: (state, action) => {
            state.graphs = action.payload;
        },
        resetGraphs: (state) => {
            state.graphs = initialState.graphs;
        },
    },
});
export const { updateGraphs, resetGraphs } = transactionsChartsSlice.actions;
export default transactionsChartsSlice.reducer;
