import { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

// redux
import { useAppSelector } from '../../hooks';
import { ChangeOldPassword } from '../../auth';
import {
    CheckboxConfirmationModal,
    ConfirmationMessage,
    UpdateSelectedItems,
    UploadImage,
    NotificationMessageModal,
} from '..';
import { MODAL_TYPES } from '../../../constants';
import CardProfilesModal from '../../fleetnet-router/fleetnetCommon/fleetNetModal/CardProfilesModal';
import CopyCardProfile from '../../fleetnet-router/fleetnetCommon/fleetNetModal/CopyCardProfile';
import ValidateTrackModal from '../../fleetnet-router/fleetnetCommon/fleetNetModal/validateTrackModal';
import DownloadModal from './DownloadModal';
import UnassignTemplates from '../../pages/digitalReceipts/receiptTemplates/UnassignTemplates';
import UpdateSitesAssignments from '../../pages/digitalReceipts/siteAssignments/UpdateSitesAssignments';

interface DialogProps {
    handleClose(): void;
}
/**
 * Renders a dialog component based on the type of modal.
 *
 * @param {DialogProps} props - The props object containing the following properties:
 * @param {Function} props.handleClose - Function to handle dialog closure
 * @return {JSX.Element} The rendered dialog component
 */
const DialogComponent = ({ handleClose }: DialogProps): JSX.Element => {
    const {
        additionalProps,
        cancelCustomLabel,
        component,
        confirmCustomLabel,
        customCheckboxLabel,
        customMessage,
        editorView,
        extendedComponent,
        fieldName,
        fileMetadata,
        formInitialValues,
        formValidations,
        imagePath,
        isCancelButtonHidden,
        labelName,
        message,
        numberOfSites,
        parent,
        saveUrl,
        selectedSites,
        selectOptions,
        showModal,
        statusControls,
        title,
        type,
        uploadImageLabel,
        getSitesAssignmentsData,
        onLeave,
        onSubmit,
        setSelectedEntitiesIds,
        notificationMessage,
    } = useAppSelector((state) => state.modal);

    const { selectedMandator } = useAppSelector((state) => state.mandators);

    const modalMaxWidth = useMemo(() => {
        switch (type) {
            case MODAL_TYPES.NotificationMessageModal:
                return 'xl';
            case MODAL_TYPES.ExportOptions:
                return 'sm';
            default:
                return 'xs';
        }
    }, [type]);

    const renderModalContent = () => {
        switch (type) {
            case MODAL_TYPES.ChangeOldPassword:
                return <ChangeOldPassword handleModalClose={handleClose} />;
            case MODAL_TYPES.UpdateSelectedItems:
                return (
                    <UpdateSelectedItems
                        statusControls={statusControls ?? []}
                        labelName={labelName ?? ''}
                        fieldName={fieldName ?? ''}
                        formInitialValues={formInitialValues ?? {}}
                        handleModalClose={handleClose}
                        onSubmit={onSubmit}
                        extendedComponent={extendedComponent}
                    />
                );
            case MODAL_TYPES.ConfirmationMessage:
                return (
                    <ConfirmationMessage
                        onClose={handleClose}
                        message={message ?? ''}
                        component={component}
                        parent={parent}
                        onConfirm={onLeave}
                        confirmCustomLabel={confirmCustomLabel}
                        cancelCustomLabel={cancelCustomLabel}
                        isCancelButtonHidden={isCancelButtonHidden}
                        customMessage={customMessage}
                    />
                );
            case MODAL_TYPES.CardProfilesModalMessage:
                return <CardProfilesModal onClose={handleClose} />;
            case MODAL_TYPES.ValidateTrackInfo:
                return <ValidateTrackModal additionalProps={additionalProps} />;
            case MODAL_TYPES.CopyCardProfile:
                return <CopyCardProfile onClose={handleClose} />;
            case MODAL_TYPES.ExportOptions:
                return (
                    <DownloadModal
                        selectOptions={selectOptions ?? []}
                        formInitialValues={formInitialValues ?? {}}
                        handleModalClose={handleClose}
                        getData={onSubmit}
                        mandatorName={selectedMandator?.name ?? ''}
                        formValidations={formValidations}
                    />
                );
            case MODAL_TYPES.UnassignTemplates:
                return (
                    <UnassignTemplates onDeleteTemplate={onLeave} numberOfSites={numberOfSites} />
                );
            case MODAL_TYPES.UpdateSitesAssignments:
                return (
                    <UpdateSitesAssignments
                        selectedSites={selectedSites ?? []}
                        getSitesAssignmentsData={getSitesAssignmentsData}
                        resetSelectedIdsVariable={setSelectedEntitiesIds}
                    />
                );
            case MODAL_TYPES.CheckboxConfirmationModal:
                return (
                    <CheckboxConfirmationModal
                        message={message ?? ''}
                        component={component}
                        customCheckboxLabel={customCheckboxLabel}
                        onConfirm={onLeave}
                    />
                );
            case MODAL_TYPES.UploadImage:
                return (
                    <UploadImage
                        saveUrl={saveUrl ?? ''}
                        fileMetadata={fileMetadata}
                        uploadImageLabel={uploadImageLabel ?? ''}
                        editorView={editorView}
                        imagePath={imagePath}
                    />
                );
            case MODAL_TYPES.NotificationMessageModal:
                return (
                    <NotificationMessageModal
                        onClose={handleClose}
                        message={message ?? ''}
                        component={component}
                        parent={parent}
                        onConfirm={onLeave}
                        cancelCustomLabel={cancelCustomLabel}
                        customMessage={customMessage}
                        notificationMessage={notificationMessage}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <Dialog open={showModal} onClose={handleClose} maxWidth={modalMaxWidth} fullWidth={true}>
            <DialogTitle>
                {title}
                {type !== MODAL_TYPES.NotificationMessageModal && (
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            padding: 0,
                        }}>
                        <Close />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent>{renderModalContent()}</DialogContent>
        </Dialog>
    );
};
export default DialogComponent;
