import { Autocomplete, AutocompleteRenderInputParams, Chip, TextField } from '@mui/material';

import { EllipsisText } from '..';

interface AutoCompleteChipProps {
    inputValues: string[];
    onChange: (value: string[]) => void;
    autoCompleteOptions?: string[];
    isDisabled?: boolean;
}

/**
 * Renders an autocomplete multi-select component with chip representing selected options.
 *
 * @param {(value: string[]) => void} props.onChange - function that handles changes in selected values.
 * @param {string[]} [props.autoCompleteOptions] - an array of strings that represents the available options in the autocomplete dropdown.
 * @param {string[]} [props.inputValues] - an array of strings representing the current selected values, which will be displayed as chips.
 * @param {boolean} [props.isDisabled] - flag indicating if the component is disabled.
 * @returns {JSX.Element} The rendered autocomplete with chip for selected values.
 */
const AutoCompleteChip = ({
    autoCompleteOptions,
    inputValues,
    isDisabled,
    onChange,
}: AutoCompleteChipProps): JSX.Element => {
    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>,
        params: AutocompleteRenderInputParams
    ) => {
        // check if the key pressed is Space or Enter
        if (event.key === ' ' || event.key === 'Enter') {
            const inputValue = (params.inputProps.value as string)?.trim();
            if (inputValue) {
                onChange([...inputValues, inputValue]);
            }

            // prevent any default behavior
            event.preventDefault();
        }
    };

    return (
        <Autocomplete
            multiple
            options={autoCompleteOptions ?? []}
            freeSolo
            clearOnBlur
            disabled={isDisabled}
            renderTags={(_, getTagProps) =>
                inputValues.map((option: string, index: number) => {
                    const { key, className, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            variant="outlined"
                            label={
                                <EllipsisText variant="body2" maxWidth={220}>
                                    {option}
                                </EllipsisText>
                            }
                            key={key}
                            sx={{ height: '25px' }}
                            className={`${className} chipStyle`}
                            {...tagProps}
                        />
                    );
                })
            }
            value={inputValues}
            onChange={(_, value: string[]) => onChange(value)}
            renderInput={(params) => (
                <TextField {...params} onKeyDown={(event) => handleKeyDown(event, params)} />
            )}
        />
    );
};

export default AutoCompleteChip;
