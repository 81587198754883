import { memo, useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SelectionRange } from '@progress/kendo-react-dateinputs';
import moment from 'moment';

// redux
import { useAppDispatch, useAppSelector } from '../../hooks';
import { updateGraphs, getTransactionsStatistics, resetGraphs } from './transactionChartsDuck';

// common components, interfaces, constants and helpers
import CardItem from '../../common/charts/CardItem';
import {
    ChartDataProps,
    ChartProps,
    DefaultFilters,
    GraphResponse,
    Graphs,
} from '../../common/interfaces';
import { INITIAL_TRANSACTION_FILTERS } from '../../../constants';
import { createDateFiltersParams } from '../../../helpers';

// style
import './transactions.scss';

const categories = [...Array(24)].map((_, index) => index.toString());

interface TransactionsChartProps {
    dateFilters: SelectionRange;
    getUpdatedStatistics: boolean;
    fields?: string[];
}

const legendPosition = 'top';

const CUSTOM_CHARTS_STYLE = { height: 145, alignItems: 'center' };

/**
 * A component that displays transaction charts based on the provided date filters and mandator.
 *
 * @param {SelectionRange} dateFilters - The date range to filter transactions by.
 * @param {boolean} getUpdatedStatistics - A flag indicating whether to retrieve updated statistics.
 * @return {JSX.Element} The transaction charts component.
 */
const TransactionsChart = memo(
    ({ dateFilters, fields, getUpdatedStatistics }: TransactionsChartProps) => {
        const dispatch = useAppDispatch();
        const { graphs } = useAppSelector((state) => state.transactionsCharts);
        const { selectedMandator } = useAppSelector((state) => state.mandators);
        const { language } = useAppSelector((state) => state.commonData);

        const [statistics, setStatistics] = useState<GraphResponse>();

        const generateGraphs = useCallback(
            (graphData: GraphResponse) => {
                const calculatedGraphs: Graphs = {};
                const hourlyData: number[] = [];
                ((graphData?.hourly_transactions as ChartDataProps[]) ?? []).forEach((item) => {
                    const momentDateAndTime = moment.utc(
                        `${moment().format('YYYY-MM-DD')} ${item.hour ?? ''}`
                    );
                    const newHour = Number(momentDateAndTime?.format('HH'));
                    if (graphData?.hourly_transactions)
                        hourlyData[newHour] = item.transactions ?? 0;
                });

                calculatedGraphs.totalAmount = {
                    kind: 'custom',
                    title: 'charts.totalAmount',
                    avatar: 'total',
                    data: graphData?.transactions_total_amount,
                };
                calculatedGraphs.transactions = {
                    kind: 'custom',
                    title: 'charts.completedTransactions',
                    avatar: 'tickets',
                    data: graphData?.transactions_count,
                };
                calculatedGraphs.dailyTransactions = {
                    kind: 'line',
                    title: 'charts.dailyTransactions',
                    data: hourlyData,
                };
                dispatch(updateGraphs(calculatedGraphs));
            },
            [dispatch, language]
        );

        const getChartsData = useCallback(
            async (filters: DefaultFilters, mandatorId: string) => {
                const { start_date, end_date } = createDateFiltersParams(dateFilters);
                const res = await dispatch(
                    getTransactionsStatistics({
                        data_state: filters,
                        mandator_id: mandatorId,
                        start_date,
                        end_date,
                        fields,
                    })
                );
                if (getTransactionsStatistics.fulfilled.match(res)) {
                    setStatistics(res.payload?.data?.[0]);
                }
            },
            [dateFilters, dispatch]
        );

        useEffect(() => {
            if (selectedMandator?.id) {
                getChartsData(INITIAL_TRANSACTION_FILTERS, selectedMandator.id);
            }
            return () => {
                dispatch(resetGraphs());
            };
        }, [dispatch, getChartsData, selectedMandator, dateFilters, getUpdatedStatistics]);

        useEffect(() => {
            if (statistics) {
                generateGraphs(statistics);
            }
        }, [statistics, generateGraphs]);

        return (
            <>
                {graphs && (
                    <Grid container mb={3} spacing={3}>
                        <Grid container item spacing={3} sm={12}>
                            <Grid item xs={12} sm={6} xl={3}>
                                <CardItem
                                    className={'transactions-grid-item'}
                                    customStyle={CUSTOM_CHARTS_STYLE}
                                    item={graphs.transactions as ChartProps}
                                    legendPosition={legendPosition}
                                    showTrending={dateFilters.start && dateFilters.end}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                                <CardItem
                                    className={'transactions-grid-item'}
                                    customStyle={CUSTOM_CHARTS_STYLE}
                                    item={graphs.totalAmount as ChartProps}
                                    legendPosition={legendPosition}
                                    showTrending={dateFilters.start && dateFilters.end}
                                />
                            </Grid>
                            <Grid item xs={12} xl={6} alignSelf={'end'}>
                                <CardItem
                                    axisTitle={'charts.hours'}
                                    categoryAxis={categories}
                                    className={'transactions-grid-item'}
                                    graphHeight={130}
                                    item={graphs.dailyTransactions as ChartProps}
                                    legendPosition={legendPosition}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    }
);
export default TransactionsChart;
