import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Interceptor } from '../../../common';
import { buildQueryParams } from '../../../../helpers';
import { URLS, INITIAL_DATA_STATE } from '../../../../constants';
import { AxiosError } from 'axios';

// interfaces
import {
    Notification,
    NotificationStateTypes,
    NotificationUpdateReqProps,
    GetRequestProps,
} from '../../interfaces';

const initialState: NotificationStateTypes = {
    notificationResult: INITIAL_DATA_STATE,
    notification: null,
    openNotificationCount: 0,
};

//create a thunk for get notifications
export const getNotifications = createAsyncThunk(
    'notifications/list',
    async ({ data_state }: GetRequestProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Notifications}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting the notification by id
export const getNotificationById = createAsyncThunk(
    'notifications/notification',
    async (notification_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Notifications}/${notification_id}`);
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for adding a notifications
export const addNotification = createAsyncThunk(
    'notifications/add',
    async (notification: Notification, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Notifications}`, { ...notification });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for updating a notification
export const updateNotification = createAsyncThunk(
    'notifications/update',
    async ({ notification, notification_id }: NotificationUpdateReqProps, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Notifications}/${notification_id}`, {
                ...notification,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for deleting a notification
export const deleteNotification = createAsyncThunk(
    'notifications/delete',
    async (notificationId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Notifications}/${notificationId}`, {});
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk to get tags for dropdown
export const getTags = createAsyncThunk('notification/getTags', async (_, { rejectWithValue }) => {
    try {
        const response = await Interceptor().get(`${URLS.Notifications}/getTags`);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.message);
    }
});

//create thunk for popup notification
export const getUnviewedNotification = createAsyncThunk(
    'notification/popup',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Notifications}/popup`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

//create thunk to mark notification as read on popup
export const markviewedNotification = createAsyncThunk(
    'notification/viewed',
    async (notificationId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.Notifications}/markasviewed?notificationId=${notificationId}`
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

//create thunk to give list of notifications depend upon mandator and roles
export const viewlistofNotification = createAsyncThunk(
    'notification/list',
    async (
        {
            startTime = '',
            endTime = '',
            tags = [],
            search = '',
        }: { startTime?: string; endTime?: string; tags?: string[]; search?: string },
        { rejectWithValue }
    ) => {
        try {
            let response;
            if (startTime && endTime && tags.length > 0 && search) {
                const tagsParam = JSON.stringify(tags);
                const url = `${URLS.Notifications}/list?filter[start_time]=${encodeURIComponent(startTime)}&filter[end_time]=${encodeURIComponent(endTime)}&filter[search]=${encodeURIComponent(search)}&filter[tags]=${encodeURIComponent(tagsParam)}`;
                response = await Interceptor().get(url);
            } else if (startTime && endTime && tags.length > 0) {
                const tagsParam = JSON.stringify(tags);
                const url = `${URLS.Notifications}/list?filter[start_time]=${encodeURIComponent(startTime)}&filter[end_time]=${encodeURIComponent(endTime)}&filter[tags]=${encodeURIComponent(tagsParam)}`;
                response = await Interceptor().get(url);
            } else if (startTime && endTime && search) {
                const url = `${URLS.Notifications}/list?filter[start_time]=${encodeURIComponent(startTime)}&filter[end_time]=${encodeURIComponent(endTime)}&filter[search]=${encodeURIComponent(search)}`;
                response = await Interceptor().get(url);
            } else if (tags.length > 0 && search) {
                const tagsParam = JSON.stringify(tags);
                const url = `${URLS.Notifications}/list?filter[search]=${encodeURIComponent(search)}&filter[tags]=${encodeURIComponent(tagsParam)}`;
                response = await Interceptor().get(url);
            } else if (startTime && endTime) {
                const url = `${URLS.Notifications}/list?filter[start_time]=${encodeURIComponent(startTime)}&filter[end_time]=${encodeURIComponent(endTime)}`;
                response = await Interceptor().get(url);
            } else if (tags.length > 0) {
                const tagsParam = JSON.stringify(tags);
                const url = `${URLS.Notifications}/list?filter[tags]=${encodeURIComponent(tagsParam)}`;
                response = await Interceptor().get(url);
            } else if (search) {
                const url = `${URLS.Notifications}/list?filter[search]=${encodeURIComponent(search)}`;
                response = await Interceptor().get(url);
            } else {
                response = await Interceptor().get(`${URLS.Notifications}/list`);
            }
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

//reducers
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotification: (state) => {
            state.notification = initialState.notification;
        },
        resetNotificationResult: (state) => {
            state.notificationResult = initialState.notificationResult;
        },
        resetOpenCount: (state) => {
            state.openNotificationCount = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
            state.notificationResult.data = [...payload.data];
            state.notificationResult.total = payload.meta.total;
            state.openNotificationCount = 0;
        });
        builder.addCase(getNotificationById.fulfilled, (state, { payload }) => {
            state.notification = payload;
        });
        builder.addCase(getUnviewedNotification.fulfilled, (state, { payload }) => {
            state.openNotificationCount = payload.meta.total;
        });
    },
});

export const { resetNotification, resetNotificationResult, resetOpenCount } =
    notificationsSlice.actions;
export default notificationsSlice.reducer;
