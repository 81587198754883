import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';

//styles
import './customDateTimePicker.scss';

interface CustomDateTimePickerProps {
    defaultValue: Date | null | undefined;
    name: string;
    onClear: () => void;
    onChange: (event: DateTimePickerChangeEvent) => void;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    format?: string;
    max?: Date;
    min?: Date;
    required?: boolean;
    touched?: FormikTouched<FormikValues>;
    value?: Date | null;
    onBlur?: (event: any) => void;
}

/**
 * Renders a custom date time picker component.
 *
 * @param {CustomDateTimePickerProps} props - The properties for the component:
 * @param {Date} [props.min] - The minimum date.
 * @param {Date} [props.max] - The maximum date.
 * @param {string} props.format - The format of the date.
 * @param {(event: DateTimePickerChangeEvent) => void} props.onChange - The change event handler.
 * @param {() => void} props.onBlur - The blur event handler.
 * @param {Date} [props.defaultValue] - The default value for the date time picker.
 * @param {boolean} [props.disabled] - Whether the component is disabled.
 * @param {boolean} [props.required] - Whether the component is required.
 * @param {string} props.name - The name of the component.
 * @param {FormikTouched<FormikValues>} [props.touched] - The touched fields.
 * @param {FormikErrors<FormikValues>} [props.errors] - The form errors.
 * @param {Date} [props.value] - The value of the date time picker.
 * @param {boolean} [props.customDateInput] - If true, custom input value will be displayed
 * @return {JSX.Element} The custom date time picker component.
 */
const CustomDateTimePicker = ({
    defaultValue,
    disabled,
    errors,
    format,
    max,
    min,
    name,
    required,
    touched,
    value,
    onBlur,
    onChange,
}: CustomDateTimePickerProps): JSX.Element => {
    const applyError = touched?.[name] && errors?.[name] ? true : false;
    const errorText = applyError ? errors?.[name] : '';

    const customBlur = () => {
        onBlur?.(name);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DateTimePicker
                id={name}
                name={name}
                defaultValue={defaultValue}
                value={value}
                min={min}
                max={max}
                format={format}
                onChange={(e) => {
                    if (e.syntheticEvent.type === 'input') {
                        return;
                    }
                    onChange(e);
                }}
                onBlur={customBlur}
                disabled={disabled}
                required={applyError && required}
            />
            <Typography
                color="#d63232"
                variant="caption"
                className="errorHeight"
                role={`error-${name}`}>
                <>{errorText}</>
            </Typography>
        </Box>
    );
};

export default memo(CustomDateTimePicker);
