import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

//redux
import { useAppDispatch, useAppSelector, useRights } from '../../../hooks';
import { getHostProvider } from '../hostProvider/hostProviderDuck';

//plugins
import { t } from 'i18next';

// common components, interfaces, constants and helpers
import { Typography } from '@mui/material';
import { CUSTOM_INITIAL_GRID_STATE } from '../../../../constants/kendoConstants';
import { updateGridColumns } from '../../../../helpers';
import { ComponentHeader, GridActionsAndFilters, GridComponent } from '../../../common';
import { CustomDataState, DefaultFilters, IGridColumn } from '../../../common/interfaces';
import { getProviderSiteDeviceColumns } from '../../gridColumn/fleetnet';
import { HostProvider, HostProvidersReqProps } from '../../interfaces/providers';

const rightsMap = new Map([['subComponent', 'services.manageFleets.fleets']]);
const FleetList = () => {
    const { hostProviders } = useAppSelector((state) => state.hostProviders);
    const [fleetInitialColumns, setFleetInitialColumns] = useState<IGridColumn[]>([]);
    const [columns, setColumns] = useState<IGridColumn[]>([]);
    const [fleetDataState, setFleetDataState] =
        useState<CustomDataState>(CUSTOM_INITIAL_GRID_STATE);
    const initialColumns = useMemo(() => getProviderSiteDeviceColumns(), []);
    const rights = useRights(rightsMap);
    const dispatch = useAppDispatch();

    //I18n
    const emptyListMsg = useMemo(() => t(`hostProvider.noHostProviderFound`), []);

    //If empty then display this component
    const NoFleetComponent = useMemo(
        () => (
            <Typography
                variant="subtitle1"
                align="center"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                {emptyListMsg}
            </Typography>
        ),
        [emptyListMsg]
    );

    useEffect(() => {
        if (fleetInitialColumns?.length === 0) {
            setColumns([...initialColumns]);
            setFleetInitialColumns([...initialColumns]);
        } else {
            updateGridColumns(fleetInitialColumns, initialColumns, setFleetInitialColumns);
        }
    }, [initialColumns, rights]);

    const onFleetDataStateChange = (event: GridDataStateChangeEvent) => {
        const { filter, group, ...state } = event.dataState;
        setFleetDataState({ ...fleetDataState, ...state });
    };

    //set default filter for mandator-id
    const defaultFilter = (
        mandatorId: string,
        dataStateFilter?: CompositeFilterDescriptor
    ): DefaultFilters => {
        //Default filter for mandatorId
        const filters: Array<FilterDescriptor | CompositeFilterDescriptor> = [
            { field: 'filter.mandatorId', operator: '', value: mandatorId },
        ];

        // If additional dataStateFilter is provided, it will be appended to the filters array.
        if (dataStateFilter) {
            if (dataStateFilter?.logic === 'or') {
                filters?.push(dataStateFilter?.filters[0]);
            } else {
                filters?.push({
                    logic: 'and',
                    filters: dataStateFilter?.filters?.concat(filters),
                });
            }
        }
        // Combines all filters with 'and' logic.
        return {
            filter: {
                logic: 'and',
                filters,
            },
        };
    };

    //Getting list of host providers
    const getHostProviderData = useCallback(
        async (dataState: CustomDataState) => {
            const dfilters = defaultFilter(localStorage?.selectedMandator, dataState?.filter);
            const requestObj: HostProvidersReqProps = {
                dataState: {
                    ...dataState,
                    filter: dfilters.filter,
                },
            };
            dispatch(getHostProvider(requestObj));
        },
        [dispatch]
    );

    useEffect(() => {
        getHostProviderData(fleetDataState);
    }, [fleetDataState, getHostProviderData]);

    //Preparing data to display on list page
    const listData = hostProviders?.data?.map((provider: HostProvider) => ({
        name: provider?.name,
        enabledSite: provider?.siteConfigurationsCount,
        enabledDevice: provider?.deviceConfigurationsCount,
    }));

    const prepareFleetnetSiteDeviceData = {
        data: listData,
        total: hostProviders?.total,
    };

    return (
        <>
            <ComponentHeader title={t('services.manageFleetnets.fleetNet')} />

            <GridActionsAndFilters
                viewRight={false}
                entityName="hostProvider.hostProviderList"
                columns={columns}
                componentServerDataState={fleetDataState}
                setComponentServerDataState={setFleetDataState}
            />

            <GridComponent
                result={prepareFleetnetSiteDeviceData}
                columns={columns}
                initialColumns={fleetInitialColumns}
                setColumns={setColumns}
                serverDataState={fleetDataState}
                onServerDataStateChange={onFleetDataStateChange}
                noDataMessage={NoFleetComponent}
                className="fleet-list-grid"
            />
        </>
    );
};

export default FleetList;
