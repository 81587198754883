import { EditorTools } from '@progress/kendo-react-editor';

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
} = EditorTools;

export enum NotificationFieldTypes {
    String = 'text',
    Number = 'number',
    Boolean = 'boolean',
}

export const NOTIFICATION_FIELD_TYPES = [
    { name: 'notificationFields.types.text', value: NotificationFieldTypes.String },
    { name: 'notificationFields.types.number', value: NotificationFieldTypes.Number },
    { name: 'notificationFields.types.boolean', value: NotificationFieldTypes.Boolean },
];

export const NOTIFICATION_EDITOR_TOOLS = [
    [Bold, Italic, Underline, Strikethrough],
    [Subscript, Superscript],
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [Indent, Outdent],
    [OrderedList, UnorderedList],
    FontSize,
    FontName,
    FormatBlock,
    [Undo, Redo],
    [Link, Unlink],
    [InsertImage, ViewHtml],
];

export const NOTIFICATION_TAGS = ['Bug Fixes', 'Improvement', 'New Feature'];

export const Release_Notes_Filter = [
    'releasedate',
    'subject',
    'mandatorcount',
    'rolecount',
    'tags',
];
