import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Formik, FormikProps, Form } from 'formik';
import {
    GridCellProps,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
} from '@progress/kendo-react-grid';
import { Add } from '@mui/icons-material';
import { Button, FormControl, Box } from '@mui/material';
import * as Yup from 'yup';
import { nanoid } from '@reduxjs/toolkit';

// redux
import { useAppDispatch, useAppSelector, useExitEdit, useRights } from '../../../hooks';
import {
    createVehicleTagsRequest,
    deleteVehicleTagsRequest,
    getVehicleTagsData,
    updateVehicleTagsRequest,
} from '../edentifyDuck';

// common
import {
    ActionButtonsCell,
    ComponentHeader,
    DropDownCell,
    FormComponentFooter,
    FormInput,
    GridComponent,
    KendoNoDataMessage,
    TextCell,
    DateCell,
} from '../../../common';
import {
    exitEdit,
    getInitialGridState,
    getStringValidations,
    handleEditButtonClick,
    handleItemChange,
    navigateToPath,
    openLeavePageModal,
    preventSubmit,
    sanitizeFields,
    showToaster,
    updateGridColumns,
    validateForm,
} from '../../../../helpers';
import { getVehicleTagsColumns } from '../../gridColumns';
import { ChangeEventParameter, CustomDataState, IGridColumn } from '../../../common/interfaces';
import { ExtendedGetReqProps, VehicleTagItem, VehicleTagsValues } from '../../interfaces';
import {
    ACTION_BUTTONS_DYNAMIC_GRIDS,
    EDIT_FIELD,
    MAXIMUM_CHARACTERS,
    vehicleTagSchemeList,
    MAXIMUM_VALUE,
    POSITIVE_NUMBER_LIMIT,
    ToasterType,
    TODAY_DATE,
    SUCCESSFUL_ACTION,
    Operations,
    Actions,
    GRID_CLASS_NAME,
} from '../../../../constants';
import { ErrorComponent } from '../../../auth';
import { staticColors } from '../../../../themes/themes';

const rightsMap = new Map([['subComponent', 'services.manageEdentify.vehicleTags']]);

const CustomCellForVehicleTagsEdit =
    (
        editVTag: (dataItem: VehicleTagItem) => void,
        deleteVTag: (dataItem: VehicleTagItem) => void,
        duplicateVTag: (dataItem: VehicleTagItem) => void,
        modelActionButtons: Map<string, boolean>
    ) =>
    (props: GridCellProps) => (
        <TextCell
            {...props}
            inputType="text"
            required
            minLimit={2}
            maxLimit={MAXIMUM_CHARACTERS}
            component={
                <ActionButtonsCell
                    {...props}
                    path=""
                    index="tag_id"
                    component="services.manageEdentify.vehicleTags"
                    buttonsVisibility={modelActionButtons}
                    editGridRow={editVTag}
                    copyGridRow={duplicateVTag}
                    deleteEntity={deleteVTag}
                />
            }
        />
    );

const SchemeCustomCell = (props: GridCellProps) => (
    <DropDownCell {...props} data={vehicleTagSchemeList} />
);

const TagTextCell = (props: GridCellProps) => <TextCell inputType="text" {...props} required />;

const VehicleTags = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { formats } = useAppSelector((state) => state.commonData);
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { vehicleTags } = useAppSelector((state) => state.edentify);
    const rights = useRights(rightsMap);

    const formRef = useRef<FormikProps<VehicleTagsValues>>(null);
    const [vehicleTagsServerDataState, setVehicleTagsServerDataState] = useState<CustomDataState>(
        getInitialGridState('tag_id')
    );
    const [columns, setColumns] = useState<IGridColumn[]>([]);
    const [noOfVtags, setNoOfVtags] = useState<number>(POSITIVE_NUMBER_LIMIT);
    const [editedIndex, setEditedIndex] = useState(0);

    useExitEdit(formRef, 'vtags', 'scrollableGrid');

    const NoVehicleTagsComponent = useMemo(
        () => <KendoNoDataMessage message={t('edentify.noVehicleTags')} />,
        [t]
    );

    const getVTagsData = useCallback(
        (mandatorId: string, serverDataState: CustomDataState) => {
            const requestObj: ExtendedGetReqProps = {
                data_state: serverDataState,
                mandator_id: mandatorId,
            };

            dispatch(getVehicleTagsData(requestObj));
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedMandator?.id) {
            getVTagsData(selectedMandator?.id, {});
        }
    }, [getVTagsData, selectedMandator?.id]);

    function onVehicleTagsDataStateChange(event: GridDataStateChangeEvent) {
        if (selectedMandator?.id) {
            const { filter, group, ...state } = event.dataState;
            setVehicleTagsServerDataState({ ...vehicleTagsServerDataState, ...state });
            getVTagsData(selectedMandator.id, state);
        }
    }

    function getInitialValues() {
        const initialValues: VehicleTagsValues = {
            vtags: [],
            toDelete: [],
        };
        if (vehicleTags) {
            initialValues.vtags = vehicleTags.data.map((el) => ({
                ...el,
                creation_date: new Date(el.creation_date),
            }));
        }

        return initialValues;
    }

    const editVehicleTagEntry = useCallback(
        (dataItem: VehicleTagItem) => {
            const currentVTags = formRef.current?.values.vtags as VehicleTagItem[];
            const index = currentVTags.findIndex((vtag) => {
                return vtag.id === dataItem.id;
            });
            setEditedIndex(index);

            const newVTags = handleEditButtonClick(dataItem.id, currentVTags);
            formRef.current?.setFieldValue('vtags', newVTags);
        },
        [formRef]
    );
    const deleteVehicleTagEntry = useCallback(
        (dataItem: VehicleTagItem) => {
            const currentVTags = formRef.current?.values.vtags as VehicleTagItem[];
            const newVTags = currentVTags?.filter((tag) => {
                return tag.id !== dataItem?.id;
            });
            formRef.current?.setFieldValue('vtags', newVTags);

            const currentToDelete = formRef.current?.values.toDelete as VehicleTagItem[];
            currentToDelete.push(dataItem);
            formRef.current?.setFieldValue('toDelete', currentToDelete);
        },
        [formRef]
    );
    const duplicateVehicleTagEntry = useCallback(
        (dataItem: VehicleTagItem) => {
            const currentVTags = formRef.current?.values.vtags as VehicleTagItem[];
            const newEditableVTag: VehicleTagItem = {
                id: nanoid(),
                tag_id: '',
                creation_date: dataItem.creation_date,
                vehicle_owner: dataItem.vehicle_owner,
                scheme: dataItem.scheme,
                plate_number: dataItem.plate_number,
                [EDIT_FIELD]: true,
            };
            currentVTags.push(newEditableVTag);

            formRef.current?.setFieldValue('vtags', currentVTags);
        },
        [formRef]
    );
    const DateTimeCustomCell = useMemo(
        () => (props: GridCellProps) => (
            <DateCell
                {...props}
                momentFormat={formats.momentDateFormat}
                dateFormat={formats.dateFormat}
            />
        ),
        [formats]
    );

    const actionButtons = useMemo(() => {
        const buttonsMap = new Map(ACTION_BUTTONS_DYNAMIC_GRIDS);
        buttonsMap.set(Actions.Copy, true);
        return buttonsMap;
    }, []);
    const customColumnsForVehicleTags = new Map<string, ComponentType<GridCellProps>>([
        ['tag_id', TagTextCell],
        ['scheme', SchemeCustomCell],
        ['creation_date', DateTimeCustomCell],
        [
            'plate_number',
            CustomCellForVehicleTagsEdit(
                editVehicleTagEntry,
                deleteVehicleTagEntry,
                duplicateVehicleTagEntry,
                actionButtons
            ),
        ],
    ]);

    function handleGridChange(event: GridItemChangeEvent) {
        const currentVTags = formRef.current?.values.vtags as VehicleTagItem[];
        const newVTags = handleItemChange(event, currentVTags, 'id');
        formRef.current?.setFieldValue('vtags', newVTags);
    }

    function updateRowData() {
        const currentVTags = formRef.current?.values.vtags ?? vehicleTags.data;
        const newVTags = exitEdit(currentVTags);
        formRef.current?.setFieldValue('vtags', newVTags);
    }

    function addGridRows() {
        const currentVTags = formRef.current?.values.vtags ?? vehicleTags.data;
        const newEditableVTag = {
            id: nanoid(),
            [EDIT_FIELD]: true,
            vehicle_owner: '',
            tag_id: '',
            plate_number: '',
        };
        const newVTags: any[] = [newEditableVTag];
        for (let i = 0; i < noOfVtags - 1; i++) {
            newVTags.push({ id: nanoid(), vehicle_owner: '' });
        }
        const updatedVTagData = [...newVTags, ...currentVTags];
        formRef.current?.setFieldValue('vtags', updatedVTagData);
    }

    function handleNumberOfRowsChange(e: ChangeEventParameter) {
        if (e.target.value) {
            setNoOfVtags(e.target.value as number);
        }
    }

    function returnToEdentifyOverview() {
        if (localStorage.addOrEdit === 'true') {
            openLeavePageModal(() => navigateToPath(navigate, '/edentify'));
        }
    }

    async function validateVehicleTagsForm() {
        const res = await formRef.current?.validateForm();
        if (res?.vtags) {
            showToaster(ToasterType.Error, t('general.validations.invalidForm'));
        }
    }

    function renderErrorComponent(props: FormikProps<VehicleTagsValues>) {
        if (props.errors.vtags) {
            const rowErrors = props.errors?.vtags[editedIndex] ?? {};

            return (
                props.errors?.vtags[editedIndex] && (
                    <ErrorComponent
                        error={rowErrors}
                        iconColor={staticColors.error}
                        marginBottom="1rem"
                        textAlertColor={staticColors.errorText}
                        alertBackgroundColor={staticColors.lightError}
                    />
                )
            );
        }
    }

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                vtags: Yup.array().of(
                    Yup.object().shape({
                        tag_id: getStringValidations(0, 36, 'tagId', true),
                        creation_date: Yup.date()
                            .max(
                                TODAY_DATE,
                                t('general.validations.createdAtMaxDate', { val: TODAY_DATE })
                            )
                            .required(t('general.validations.required.createdAt')),
                        scheme: Yup.string()
                            .equals(vehicleTagSchemeList.map((el) => el.value))
                            .required(t('general.validations.required.scheme')),
                        plate_number: getStringValidations(0, 36, 'plateNumber', true),
                    })
                ),
            }),
        [t]
    );

    async function handleSubmit(fields: VehicleTagsValues) {
        fields = sanitizeFields(fields);

        fields.vtags.forEach((el) => {
            delete el.inEdit;
            el.vehicle_owner = el.vehicle_owner ?? '';
        });
        const tagsToCreate: VehicleTagItem[] = [];
        const tagsToUpdate: VehicleTagItem[] = [];
        const tagsToDelete: VehicleTagItem[] = fields.toDelete;
        for (const vtag of fields.vtags) {
            if (vtag.id?.length === 36) {
                tagsToUpdate.push(vtag);
            } else {
                delete vtag.id;
                tagsToCreate.push(vtag);
            }
        }

        const results = await Promise.allSettled([
            tagsToCreate.length &&
                dispatch(createVehicleTagsRequest({ vehicle_tags: tagsToCreate })),
            tagsToUpdate.length &&
                dispatch(updateVehicleTagsRequest({ vehicle_tags: tagsToUpdate })),
            tagsToDelete.length &&
                dispatch(deleteVehicleTagsRequest({ vehicle_tags: tagsToDelete })),
        ]);

        if (
            !results.filter(
                (res) =>
                    res.status === 'rejected' ||
                    (res.status === 'fulfilled' && (res.value as any).error !== undefined)
            ).length
        ) {
            showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Updated);

            localStorage.removeItem('addOrEdit');
            return;
        }
        showToaster(ToasterType.Error, t('general.messages.notSaved'));
    }

    useEffect(() => {
        const initialColumns = getVehicleTagsColumns(customColumnsForVehicleTags);
        if (columns.length === 0) {
            setColumns(initialColumns);
        } else {
            updateGridColumns(columns, initialColumns, setColumns);
        }
    }, []);

    return (
        <>
            <ComponentHeader title={t('services.manageEdentify.vehicleTags')} />
            {rights?.subComponent.edit ? (
                <Formik
                    innerRef={formRef}
                    initialValues={getInitialValues()}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validate={(fields) => validateForm(fields)}>
                    {(props) => (
                        <Form noValidate onKeyDown={preventSubmit}>
                            <Box
                                className={GRID_CLASS_NAME}
                                sx={{ textAlign: 'left', mt: 0, ml: 0 }}>
                                <FormInput
                                    fieldName="numberOfRows"
                                    value={noOfVtags}
                                    type="number"
                                    minLimit={POSITIVE_NUMBER_LIMIT}
                                    maxLimit={MAXIMUM_VALUE}
                                    label={t('parking.freeParking.rows')}
                                    handleChange={handleNumberOfRowsChange}
                                    className="formControl"
                                    sx={{
                                        minWidth: '14rem !important',
                                        marginLeft: '0px !important',
                                    }}
                                    numberFormat="n0"
                                />
                                <FormControl size="small">
                                    <Button
                                        variant="contained"
                                        aria-label="add-grid-rows"
                                        role="add-grid-rows"
                                        sx={{
                                            mt: '1.78rem',
                                            ml: 1,
                                            py: 1,
                                        }}
                                        onClick={addGridRows}>
                                        <Add />
                                    </Button>
                                </FormControl>
                                {renderErrorComponent(props)}
                                <GridComponent
                                    result={props.values.vtags}
                                    initialColumns={getVehicleTagsColumns(
                                        customColumnsForVehicleTags
                                    )}
                                    columns={columns}
                                    setColumns={setColumns}
                                    serverDataState={vehicleTagsServerDataState}
                                    onServerDataStateChange={onVehicleTagsDataStateChange}
                                    noDataMessage={NoVehicleTagsComponent}
                                    handleItemChange={handleGridChange}
                                    updateRowData={updateRowData}
                                    isPageable={false}
                                    enterCellEdit={editVehicleTagEntry}
                                    className="vehicle-tags-edit-grid"
                                />
                            </Box>

                            <FormComponentFooter
                                handleFormValidation={validateVehicleTagsForm}
                                cancelAction={returnToEdentifyOverview}
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <GridComponent
                    result={vehicleTags}
                    columns={getVehicleTagsColumns()}
                    initialColumns={getVehicleTagsColumns()}
                    setColumns={setColumns}
                    serverDataState={vehicleTagsServerDataState}
                    onServerDataStateChange={onVehicleTagsDataStateChange}
                    noDataMessage={NoVehicleTagsComponent}
                    isPageable={true}
                    className="vehicle-tags-view-grid"
                />
            )}
        </>
    );
};

export default VehicleTags;
