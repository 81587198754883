import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GridDataStateChangeEvent, GridDetailRowProps } from '@progress/kendo-react-grid';

// redux
import { useAppDispatch } from '../../../hooks';
import { getUnitsBySiteId, updateEdentifyUnits } from '../edentifyDuck';
import { getPointsBySiteId } from '../../points/pointsDuck';

// common
import { GridComponent, KendoNoDataMessage } from '../../../common';
import { CustomDataState, GridDataResult, IGridColumn } from '../../../common/interfaces';
import { SiteUnit } from '../../interfaces';
import { getEdentifySiteUnitsColumns } from '../../gridColumns';

export const SitePointUnitsViewer = ({ dataItem }: GridDetailRowProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [siteUnitsServerDataState, setSiteUnitsServerDataState] = useState<CustomDataState>({});
    const [siteUnits, setSiteUnits] = useState<GridDataResult<SiteUnit>>({ data: [], total: 0 });
    const [siteUnitsColumns, setSiteUnitsColumns] = useState<IGridColumn[]>([]);

    const NoSiteUnitsComponent = useMemo(
        () => <KendoNoDataMessage message={t('points.noDataSite')} />,
        [t]
    );

    async function fetchSiteUnits(site_id: string, data_state: CustomDataState) {
        const response = await dispatch(getPointsBySiteId({ data_state, site_id }));
        if (getPointsBySiteId.fulfilled.match(response)) {
            const unitsRes = await dispatch(getUnitsBySiteId({ data_state, site_id }));
            dispatch(updateEdentifyUnits(unitsRes.payload));
            const pointsR = response.payload.data;
            const edentifyUnitsR = unitsRes.payload.data;

            const unitsMap = new Map<string, string>();
            const units: SiteUnit[] = [];

            if (!pointsR.length) {
                setSiteUnits({ data: [], total: 0 });
            } else {
                for (const unit of edentifyUnitsR) {
                    unitsMap.set(unit.point_id, unit.tag);
                }
                for (const point of pointsR) {
                    units.push({
                        name: point.name,
                        point_id: point.id,
                        unit_tag: unitsMap.get(point.id) ?? '',
                    });
                }

                setSiteUnits({ data: units, total: units.length });
            }
        }
    }

    useEffect(() => {
        fetchSiteUnits(dataItem.id, siteUnitsServerDataState);
    }, [dataItem.id]);

    useEffect(() => {
        setSiteUnitsColumns(getEdentifySiteUnitsColumns());
    }, []);

    const onSiteUnitsDataStateChange = (event: GridDataStateChangeEvent) => {
        const sortState = { sort: event.dataState.sort };
        setSiteUnitsServerDataState(sortState);
        fetchSiteUnits(dataItem.id, sortState);
    };

    return (
        <GridComponent
            result={siteUnits}
            columns={siteUnitsColumns}
            initialColumns={getEdentifySiteUnitsColumns()}
            setColumns={setSiteUnitsColumns}
            serverDataState={siteUnitsServerDataState}
            onServerDataStateChange={onSiteUnitsDataStateChange}
            noDataMessage={NoSiteUnitsComponent}
            isPageable={false}
            className="site-units-view-grid"
        />
    );
};
